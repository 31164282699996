import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {updateAppState, appState} from '../../app/appSlice';
import Logo from '../../assets/logo.svg';

// route paths
const paths = [
  {
    type: 'ROOT',
    path: '/h/:siteid'
  },
  {
    type: 'TAG',
    path: '/h/:siteid/:tagid'
  },
  {
    type: 'POST',
    path: '/h/:siteid/:tagid/:postid'
  }
];

// db path
const path = '/userHost/agility-cycle-blog/post';

const Site = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentAppState = useSelector(appState);
  const {cardComponent} = currentAppState;
  const {siteid} = cardComponent;
  const [paginationEnd, setPaginationEnd] = useState(false);
  const [postLoaded, setPostLoaded] = useState(false);
  const [postList, setPostList] = useState([]);
  const [lastId, setLastId] = useState('');

  const switchPage = (tagid, postid) => {
    setLastId('');
    setPostList([]);
    setPostLoaded(false);
    setPaginationEnd(false);
    const cardComponent = {
      type: '',
      siteid: undefined,
      tagid: undefined,
      postid: undefined
    }
    const newAppState = Object.assign({...currentAppState}, {
      cardComponent
    });
    dispatch(updateAppState(newAppState));
    navigate(`/h/${siteid}/${tagid ? tagid + '/' : ''}${postid || ''}`, { replace: true });
  }

  // browser's back/forward button
  useEffect(() => {
    window.onpopstate = () => {
      switchPage();
    }
  })

	return (<div className="h-full max-w-5xl mx-auto px-2">
    <div className="my-20 text-center">
      <img src={Logo} className="w-36 mx-auto" />
    </div>
    <div className="text-[#FDC859] text-center text-3xl mb-14">
      Software is a breeze!
    </div>
    <div className="text-[#FDC859] text-center text-4xl mb-10 leading-loose">
      <div className="mb-7">
        Require data engineering?
      </div>
      <div className="flex items-center justify-center mb-12">
        <svg className="w-fit h-[100px] mr-7" fill="#FDC859" version="1.1" id="Layer_1" viewBox="0 0 490.667 490.667">
            <path d="M245.333,0C172.928,0,96,22.421,96,64v192c0,42.027,75.115,64,149.333,64s149.333-21.973,149.333-64V64
              C394.667,22.421,317.739,0,245.333,0z M373.333,256c0,20.181-52.565,42.667-128,42.667s-128-22.485-128-42.667v-29.909
              c27.883,19.584,78.933,29.909,128,29.909s100.117-10.325,128-29.909V256z M373.333,192c0,17.813-48.704,42.667-128,42.667
              s-128-24.853-128-42.667v-29.909c27.883,19.584,78.933,29.909,128,29.909s100.117-10.325,128-29.909V192z M373.333,128
              c0,17.813-48.704,42.667-128,42.667s-128-24.853-128-42.667V98.091c27.883,19.584,78.933,29.909,128,29.909
              s100.117-10.325,128-29.909V128z M245.333,106.667c-79.296,0-128-24.853-128-42.667c0-17.813,48.704-42.667,128-42.667
              s128,24.853,128,42.667C373.333,81.813,324.629,106.667,245.333,106.667z"/>
            <path d="M248.661,384.405l-3.157-0.533C226.795,380.821,224,375.957,224,373.333c0-4.245,8.512-10.667,21.355-10.667
              c12.608,0,20.928,5.995,21.355,10.304c0.555,5.867,5.739,10.389,11.627,9.6c5.867-0.555,10.155-5.76,9.6-11.627
              c-1.621-16.896-19.925-29.632-42.603-29.632c-23.936,0-42.688,14.059-42.688,32c0,16.704,13.248,27.328,39.403,31.595
              l1.728-10.517l1.429,11.051c17.728,2.923,21.44,7.36,21.44,10.56c0,4.245-8.512,10.667-21.355,10.667
              c-12.608,0-20.928-6.016-21.355-10.325c-0.555-5.867-5.611-10.24-11.627-9.6c-5.867,0.555-10.155,5.76-9.6,11.627
              C204.331,435.264,222.635,448,245.312,448C269.248,448,288,433.941,288,416C288,399.317,274.773,388.693,248.661,384.405z"/>
            <path d="M394.667,373.333c0-17.643-14.357-32-32-32h-21.333c-17.643,0-32,14.357-32,32V416c0,17.643,14.357,32,32,32h21.333
              c4.928,0,9.536-1.216,13.696-3.2l0.085,0.085c2.091,2.069,4.821,3.115,7.552,3.115s5.461-1.045,7.531-3.115
              c4.16-4.16,4.16-10.923,0-15.083l-0.085-0.085c2.005-4.16,3.221-8.789,3.221-13.717V373.333z M373.333,411.605l-3.136-3.136
              c-4.16-4.16-10.923-4.16-15.083,0c-4.16,4.16-4.16,10.923,0,15.083l3.115,3.115h-16.896c-5.888,0-10.667-4.779-10.667-10.667
              v-42.667c0-5.888,4.779-10.667,10.667-10.667h21.333c5.888,0,10.667,4.779,10.667,10.667V411.605z"/>
            <path d="M469.333,426.667h-32V352c0-5.888-4.779-10.667-10.667-10.667c-5.888,0-10.667,4.779-10.667,10.667v85.333
              c0,5.888,4.779,10.667,10.667,10.667h42.667c5.888,0,10.667-4.779,10.667-10.667C480,431.445,475.221,426.667,469.333,426.667z"/>
            <path d="M175.445,385.109c-5.291-2.624-11.669-0.491-14.315,4.779l-11.797,23.616l-11.797-23.595
              c-2.645-5.269-9.045-7.403-14.315-4.779c-5.269,2.645-7.424,9.045-4.779,14.315l18.965,37.909l-18.944,37.867
              c-2.624,5.269-0.491,11.669,4.779,14.315c1.536,0.768,3.157,1.131,4.757,1.131c3.904,0,7.659-2.155,9.557-5.909l42.667-85.333
              C182.848,394.155,180.715,387.755,175.445,385.109z"/>
            <path d="M88.704,341.888c-4.331-1.472-9.152,0.043-11.904,3.712l-23.467,31.275L29.867,345.6
              c-2.752-3.669-7.509-5.184-11.904-3.712c-4.352,1.451-7.296,5.525-7.296,10.112v85.333c0,5.888,4.779,10.667,10.667,10.667
              S32,443.221,32,437.333V384l12.8,17.067c4.032,5.376,13.056,5.376,17.067,0L74.667,384v53.333c0,5.888,4.779,10.667,10.667,10.667
              S96,443.221,96,437.333V352C96,347.413,93.056,343.339,88.704,341.888z"/>
        </svg>
        <svg className="w-fit h-[90px] mr-7" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.79 1.574h3.866c.14 0 .252.11.252.246v5.186a.25.25 0 01-.252.246H6.344c-.975 0-1.766.77-1.766 1.72v1.162a.25.25 0 01-.253.243H1.867a.25.25 0 01-.253-.246V6.177a.25.25 0 01.252-.246H7.98c.418 0 .757-.33.757-.737a.747.747 0 00-.757-.738H5.537V1.82a.25.25 0 01.253-.246zm5.632 2.592V1.82c0-.95-.79-1.72-1.766-1.72H5.79c-.976 0-1.767.77-1.767 1.72v2.636H1.867C.89 4.456.1 5.226.1 6.176v3.955c0 .95.79 1.72 1.766 1.72h2.46c.085 0 .17-.006.252-.017v2.346c0 .95.79 1.72 1.766 1.72h3.866c.976 0 1.767-.77 1.767-1.72v-2.636h2.156c.976 0 1.767-.77 1.767-1.72V5.868c0-.95-.79-1.72-1.767-1.72h-2.458c-.086 0-.17.005-.253.017zm-5.33 5.974V8.994a.25.25 0 01.252-.246h3.312c.976 0 1.766-.77 1.766-1.72V5.866a.25.25 0 01.253-.243h2.458c.14 0 .253.11.253.246v3.954a.25.25 0 01-.252.246H8.02a.747.747 0 00-.757.737c0 .408.339.738.757.738h2.442v2.636a.25.25 0 01-.253.246H6.344a.25.25 0 01-.252-.246v-4.04z" fill="#FDC859"/>
        </svg>
        <svg className="w-fit h-[60px]" fill="#FDC859" viewBox="0 0 205.4 76.7" xmlns="http://www.w3.org/2000/svg">
          <path class="st0" d="M15.5,23.2c-0.4,0-0.5-0.2-0.3-0.5l2.1-2.7c0.2-0.3,0.7-0.5,1.1-0.5h35.7c0.4,0,0.5,0.3,0.3,0.6l-1.7,2.6      c-0.2,0.3-0.7,0.6-1,0.6L15.5,23.2z"/>
          <path class="st0" d="M0.4,32.4c-0.4,0-0.5-0.2-0.3-0.5l2.1-2.7c0.2-0.3,0.7-0.5,1.1-0.5h45.6c0.4,0,0.6,0.3,0.5,0.6l-0.8,2.4      c-0.1,0.4-0.5,0.6-0.9,0.6L0.4,32.4z"/>
          <path class="st0" d="M24.6,41.6c-0.4,0-0.5-0.3-0.3-0.6l1.4-2.5c0.2-0.3,0.6-0.6,1-0.6h20c0.4,0,0.6,0.3,0.6,0.7L47.1,41      c0,0.4-0.4,0.7-0.7,0.7L24.6,41.6z"/>
          <path class="st0" d="M128.4,21.4c-6.3,1.6-10.6,2.8-16.8,4.4c-1.5,0.4-1.6,0.5-2.9-1c-1.5-1.7-2.6-2.8-4.7-3.8       c-6.3-3.1-12.4-2.2-18.1,1.5c-6.8,4.4-10.3,10.9-10.2,19c0.1,8,5.6,14.6,13.5,15.7c6.8,0.9,12.5-1.5,17-6.6       c0.9-1.1,1.7-2.3,2.7-3.7c-3.6,0-8.1,0-19.3,0c-2.1,0-2.6-1.3-1.9-3c1.3-3.1,3.7-8.3,5.1-10.9c0.3-0.6,1-1.6,2.5-1.6 c5.1,0,23.9,0,36.4,0c-0.2,2.7-0.2,5.4-0.6,8.1c-1.1,7.2-3.8,13.8-8.2,19.6c-7.2,9.5-16.6,15.4-28.5,17 c-9.8,1.3-18.9-0.6-26.9-6.6c-7.4-5.6-11.6-13-12.7-22.2c-1.3-10.9,1.9-20.7,8.5-29.3c7.1-9.3,16.5-15.2,28-17.3 c9.4-1.7,18.4-0.6,26.5,4.9c5.3,3.5,9.1,8.3,11.6,14.1C130,20.6,129.6,21.1,128.4,21.4z"/>
          <path class="st0" d="M161.5,76.7c-9.1-0.2-17.4-2.8-24.4-8.8c-5.9-5.1-9.6-11.6-10.8-19.3c-1.8-11.3,1.3-21.3,8.1-30.2       c7.3-9.6,16.1-14.6,28-16.7c10.2-1.8,19.8-0.8,28.5,5.1c7.9,5.4,12.8,12.7,14.1,22.3c1.7,13.5-2.2,24.5-11.5,33.9       c-6.6,6.7-14.7,10.9-24,12.8C166.8,76.3,164.1,76.4,161.5,76.7z M185.3,36.3c-0.1-1.3-0.1-2.3-0.3-3.3 c-1.8-9.9-10.9-15.5-20.4-13.3c-9.3,2.1-15.3,8-17.5,17.4c-1.8,7.8,2,15.7,9.2,18.9c5.5,2.4,11,2.1,16.3-0.6 C180.5,51.3,184.8,44.9,185.3,36.3z"/>
        </svg>
      </div>
      <div className="text-[#FB61B7] text-2xl leading-[55px]">
        <div>
          1. <b className="font-black">YES.</b> Can help with SQL
        </div>
        <div>
          2. <b className="font-black">YES.</b> Can help with NoSQL
        </div>
        <div>
          3. <b className="font-black">YES.</b> Can help with Python
        </div>
        <div>
          4. <b className="font-black">YES.</b> Can help with Golang
        </div>
      </div>
    </div>
    <div className="text-[#FDC859] text-center text-4xl mb-8 leading-none">
      Check <u className="text-[#FB61B7] leading-loose">my</u> example code!
    </div>
    <div>
      <a href="https://github.com/agilitycycle" target="_blank">
        <svg className="fill-[#FB61B7] mx-auto mb-6" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 50 50">
          <path d="M17.791,46.836C18.502,46.53,19,45.823,19,45v-5.4c0-0.197,0.016-0.402,0.041-0.61C19.027,38.994,19.014,38.997,19,39 c0,0-3,0-3.6,0c-1.5,0-2.8-0.6-3.4-1.8c-0.7-1.3-1-3.5-2.8-4.7C8.9,32.3,9.1,32,9.7,32c0.6,0.1,1.9,0.9,2.7,2c0.9,1.1,1.8,2,3.4,2 c2.487,0,3.82-0.125,4.622-0.555C21.356,34.056,22.649,33,24,33v-0.025c-5.668-0.182-9.289-2.066-10.975-4.975 c-3.665,0.042-6.856,0.405-8.677,0.707c-0.058-0.327-0.108-0.656-0.151-0.987c1.797-0.296,4.843-0.647,8.345-0.714 c-0.112-0.276-0.209-0.559-0.291-0.849c-3.511-0.178-6.541-0.039-8.187,0.097c-0.02-0.332-0.047-0.663-0.051-0.999 c1.649-0.135,4.597-0.27,8.018-0.111c-0.079-0.5-0.13-1.011-0.13-1.543c0-1.7,0.6-3.5,1.7-5c-0.5-1.7-1.2-5.3,0.2-6.6 c2.7,0,4.6,1.3,5.5,2.1C21,13.4,22.9,13,25,13s4,0.4,5.6,1.1c0.9-0.8,2.8-2.1,5.5-2.1c1.5,1.4,0.7,5,0.2,6.6c1.1,1.5,1.7,3.2,1.6,5 c0,0.484-0.045,0.951-0.11,1.409c3.499-0.172,6.527-0.034,8.204,0.102c-0.002,0.337-0.033,0.666-0.051,0.999 c-1.671-0.138-4.775-0.28-8.359-0.089c-0.089,0.336-0.197,0.663-0.325,0.98c3.546,0.046,6.665,0.389,8.548,0.689 c-0.043,0.332-0.093,0.661-0.151,0.987c-1.912-0.306-5.171-0.664-8.879-0.682C35.112,30.873,31.557,32.75,26,32.969V33 c2.6,0,5,3.9,5,6.6V45c0,0.823,0.498,1.53,1.209,1.836C41.37,43.804,48,35.164,48,25C48,12.318,37.683,2,25,2S2,12.318,2,25 C2,35.164,8.63,43.804,17.791,46.836z"></path>
        </svg>
        <p className="text-[#FB61B7] text-3xl text-center mb-14">See here.</p>
      </a>
    </div>
    <div className="text-center mb-16">
      <div className="mb-9 flex-col items-center">
        <div className="text-[#FB61B7] text-2xl mb-12">
          &rarr;&nbsp;&nbsp;Silverstripe with Docker 
          <span className="ml-3 bg-[#FB61B7] text-[#000523] text-xs font-medium me-2 px-2.5 py-0.5 rounded-sm">
            Public
          </span>
        </div>
        <div className="text-[#FB61B7] text-2xl mb-12">
          &rarr;&nbsp;&nbsp;Redux and Saga 
          <span className="ml-3 bg-[#FB61B7] text-[#000523] text-xs font-medium me-2 px-2.5 py-0.5 rounded-sm">
            Public
          </span>
        </div>
        <div className="text-[#FB61B7] text-2xl mb-12">
          &rarr;&nbsp;&nbsp;Nuxt example 
          <span className="ml-3 bg-[#FB61B7] text-[#000523] text-xs font-medium me-2 px-2.5 py-0.5 rounded-sm">
            Public
          </span>
        </div>
        <div className="text-[#FB61B7] text-2xl mb-12">
          &rarr;&nbsp;&nbsp;VueJS example 
          <span className="ml-3 bg-[#FB61B7] text-[#000523] text-xs font-medium me-2 px-2.5 py-0.5 rounded-sm">
            Public
          </span>
        </div>
        <div className="text-[#FB61B7] text-2xl mb-12">
          &rarr;&nbsp;&nbsp;Notion example 
          <span className="ml-3 bg-[#FB61B7] text-[#000523] text-xs font-medium me-2 px-2.5 py-0.5 rounded-sm">
            Public
          </span>
        </div>
        <div className="text-[#FB61B7] text-2xl mb-12">
          &rarr;&nbsp;&nbsp;OpenAI 
          <span className="ml-3 bg-[#FB61B7] text-[#000523] text-xs font-medium me-2 px-2.5 py-0.5 rounded-sm">
            Private
          </span>
        </div>
        <div className="text-[#FB61B7] text-2xl mb-12">
          &rarr;&nbsp;&nbsp;SQL Queries.......... In progress
        </div>
        <div className="text-[#FB61B7] text-2xl mb-12">
          &rarr;&nbsp;&nbsp;GraphQL............. In progress
        </div>
        <div className="text-[#FB61B7] text-2xl mb-12">
          &rarr;&nbsp;&nbsp;ASP.NET............. In progress
        </div>
        <div className="text-[#FB61B7] text-2xl mb-12">
          &rarr;&nbsp;&nbsp;VueJS.................. In progress
        </div>
        <div className="text-[#FB61B7] text-2xl mb-12">
          &rarr;&nbsp;&nbsp;AngularJS........... In progress
        </div>
      </div>
    </div>
    <div className="w-full sm:w-6/12 mx-auto text-[#FDC859] text-center text-2xl mb-12 leading-loose">
      Call James to manage your project remotely. <b>Starting from 2.8K per month, or $50 USD per hour.</b>
    </div>
    <div className="max-w-[325px] mx-auto">
      <div className="mb-9 flex flex-row items-center">
        <div className="flex items-center justify-center w-16 h-16 block bg-[#FB61B7] text-[#000523] text-3xl text-center rounded-full">1</div>
        <div className="text-[#FB61B7] text-3xl ml-7">
          Product sense
        </div>
      </div>
      <div className="mb-9 flex flex-row items-center">
        <div className="flex items-center justify-center w-16 h-16 block bg-[#FB61B7] text-[#000523] text-3xl text-center rounded-full">2</div>
        <div className="text-[#FB61B7] text-3xl ml-7">
          Data sense
        </div>
      </div>
      <div className="mb-20 flex flex-row items-center">
        <div className="flex items-center justify-center w-16 h-16 block bg-[#FB61B7] text-[#000523] text-3xl text-center rounded-full">3</div>
        <div className="text-[#FB61B7] text-3xl ml-7">
          Customer sense
        </div>
      </div>
    </div>
    <div className="text-[#FDC859] text-center text-2xl pb-20">
      james@agilitycycle.com
    </div>
  </div>);
};

export default Site;