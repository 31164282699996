import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  Navigate,
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom';
import { store } from './app/store';
import { Provider } from 'react-redux';
import {
  Site
} from './screens/general';
import './index.css';
import reportWebVitals from './reportWebVitals';

const router = createBrowserRouter([
  {
    path: '/',
    children: [
      {
        index: true,
        element: <Navigate replace to='/h/agility-cycle' />,
      }
    ],
  },
  {
    path: '/h/:siteid',
    element: <Site/>,
  },
  {
    path: '/h/:siteid/:tag',
    element: <Site/>,
  },
  {
    path: '/h/:siteid/:tag/:postdetails',
    element: <Site/>,
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
